<template>
  <div className="depositFee">
    <img src="src/assets/image/active.png" style="margin-bottom: 10px;width: 100%"/>
    <div className="pubBox">
      <div className="list">
        <div className="listTitle">缴费单位</div>
        <div>璧山区生鲜时长</div>
      </div>
      <div className="list">
        <div className="listTitle">户主信息</div>
        <div>璧山区生鲜时长</div>
      </div>
      <div className="list">
        <div className="listTitle">费用金额</div>
        <div>璧山区生鲜时长</div>
      </div>
      <div className="list">
        <div className="listTitle">已缴金额</div>
        <div>璧山区生鲜时长</div>
      </div>
      <div className="line"></div>
      <div className="list">
        <div className="font28 fontBold">应缴总金额</div>
        <div className="font40 fontBold">4454</div>
      </div>
      <div className="list">
        <div className="listTitle">账户余额抵扣</div>
        <div style="color: #FD6336">璧山区生鲜时长</div>
      </div>
      <div className="list">
        <div className="listTitle">活动折扣率</div>
        <div style="color: #FD6336">0.5折</div>
      </div>
    </div>
    <div className="pubBox">
      <div className="fontBold font32 list">缴费金额</div>
      <div className="list fontBold font48">￥6565456</div>
      <div className="inputMoney">
        <span>￥</span><input placeholder="点击输入缴费金额"/>
      </div>
    </div>
    <div className="tip">最高缴费金额不能超过费用金额。</div>
    <div className="btn">确认缴纳</div>
    <div className="pubBox">
      <div style="display: flex;flex-direction: column;align-items: center;justify-content: center">
        <img src="src/assets/image/depositFee.png">
        <div className="fontBold font48" style="width: 290px;text-align: center;margin-top: 16px;">恭喜，您的“保证金”已缴纳完成
        </div>
      </div>
      <div className="line"></div>
      <div className="list">
        <div className="listTitle">缴费单位</div>
        <div>璧山区生鲜时长</div>
      </div>
      <div className="list">
        <div className="listTitle">户主信息</div>
        <div>璧山区生鲜时长</div>
      </div>
      <div className="list">
        <div className="listTitle">费用金额</div>
        <div>璧山区生鲜时长</div>
      </div>
      <div className="line"></div>
      <div className="list">
        <div className="font28 fontBold">已缴金额</div>
        <div className="font40 fontBold">4454</div>
      </div>
    </div>
    <div className="help">
      <div>疑问？</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'depositFee',
};
</script>

<style scoped lang="scss">
.depositFee {

  flex: 1;
  padding: 25px;
  box-sizing: border-box;
  font-size: 28px;
  font-weight: 400;
  background-color: #F9F9F9;
  min-height: 100vh;

  .help {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    div {
      text-align: center;
      width: 100%;
      height: 28px;
      font-size: 24px;
      font-weight: 500;
      color: #000000;
    }
  }

  .btn {
    height: 96px;
    background: #85C226;
    border-radius: 200px 200px 200px 200px;
    text-align: center;
    line-height: 96px;
    font-size: 36px;
    color: #FFFFFF;
    margin: 20px 0;
  }

  .tip {
    flex: 1;
    font-size: 24px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.6);
  }

}
</style>
